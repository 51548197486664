var ko = require("knockout");
var Browser = require("../Core/BrowserControl");
var ComponentEvent = require("../Core/ComponentEvent");

var w = window;


function QrCodePinHelpDialogContentControl()
{
    var _this = this;

    
    _this.onRegisterDialog = ComponentEvent.create();
    _this.onUnregisterDialog = ComponentEvent.create();
    
}

ko.components.register("qr-code-pin-help-dialog-content-control",
    {
        viewModel: QrCodePinHelpDialogContentControl,
        template: require("html/LoginPage/Controls/QrCodePinHelpDialogContentControlHtml.html"),
        synchronous: !w.ServerData.iMaxStackForKnockoutAsyncComponents || Browser.Helper.isStackSizeGreaterThan(w.ServerData.iMaxStackForKnockoutAsyncComponents),
        enableExtensions: true
    });

module.exports = QrCodePinHelpDialogContentControl;