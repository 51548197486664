/* *********************
This file shares some functionality with the npm package in src/SISU/telemetry. When you make a change here, consider if
it also needs to apply to the "modern" (React) Axis stack -- if so, you should update both places.
********************* */

namespace Telemetry
{
    declare var require: any;

    const Utils = require("./Utils");
    const { EventApi, EmptyEventProvider, OneDSEventProvider } = require("./EventApi");

    const _w: any = window;
    const Constants = require("./Constants");
    /**
     * Configuration options related to the hosting IDP.
     */
    export interface IIDPConfig
    {
        actorID: string;
        hostPageID: string;
        correlationID: string;
        pageName: string;
        serviceID: string;
        serverDetails: IServerDetails;
        appId: string;
        environment: string;
    }

    /**
     * Configuration options for the client event API. Properties defined directly on this object are
     * generally burned in on the server.
     */
    export interface IClientEventsConfig extends IEventApiArgs, IIDPConfig
    {
        minEvents: number;
        useOneDSEventApi: boolean;
        telemetryEnabled: boolean;
    }

    export interface IServerDetails
    {
        datacenter: string;
        roleInstance: string;
        version: string;
    }

    /**
     * The context that is passed to pre-send handlers when they are invoked.
     */
    interface IOnInvokeContext
    {
        isViewTransition: boolean;
    }

    export class TelemetryHelper
    {
        private _nonIndexedDataPoint: any = {};
        private _clientEventsConfig: IClientEventsConfig;
        private _eventApi: EventApi<IEventProvider>;
        private _serverPageID: string;
        private _serviceID: string;
        private _enabled: boolean = true;

        /**
         * Constructs an instance of the TelemetryHelper object. This will initialize
         * the event provider specified in the client event config.
         * @param clientEventsConfig The client event configuration
         */
        constructor(clientEventsConfig: IClientEventsConfig)
        {
            this._clientEventsConfig = clientEventsConfig || ({} as any);

            this._enabled = this._clientEventsConfig.telemetryEnabled;

            if (!this._enabled)
            {
                return;
            }

            if (this._clientEventsConfig.useOneDSEventApi)
            {
                this._eventApi = new EventApi(new OneDSEventProvider());
            }
            else
            {
                this._eventApi = new EventApi(new EmptyEventProvider());
            }

            // Pin hpgid for the lifetime of the Telemetry object -- it should only get reset on navigation
            this._serverPageID = this._clientEventsConfig.hostPageID ? this._clientEventsConfig.hostPageID.toString() : "";
            this._serviceID = this._clientEventsConfig.serviceID ? this._clientEventsConfig.serviceID.toString() : "";

            this._eventApi.initialize(this._clientEventsConfig);

            this.addPreSendHandler(
                (isViewTransition: boolean) =>
                {
                    this._setPerRequestDataPoints(isViewTransition);
                });

            // For flushing the pending events every minute
            if (this._clientEventsConfig.autoPost)
            {
                const self = this;

                setInterval(
                    () =>
                    {
                        if (self.hasPageEvents() || self._eventApi.hasEvents())
                        {
                            self.post(false);
                        }
                    },
                    this._clientEventsConfig.flush);
            }

            this._addUnloadHandlers();
        }

        /**
         * Get the value for a data point
         * @param name The name of the data point to get
         * @param isIndexed Whether the data point to retrieve is indexed
         * @param category Type of the common schema fields
         */
        get(name: string, category = "data", isIndexed: boolean = false): any
        {
            if (!this._enabled) { return; }

            if (isIndexed)
            {
                return this._eventApi.get(name, category);
            }

            return this._nonIndexedDataPoint[name];
        }

        /**
         * Set the value of a data point
         * @param name The name of the data point to set
         * @param data The value of the data point
         * @param isIndexed Whether the data point is indexed
         * @param category Type of the common schema fields
         */
        set(name: string, data: any, isIndexed: boolean = false, category?: string): void
        {
            if (!this._enabled)
            {
                return;
            }

            if (isIndexed)
            {
                this._eventApi.set(name, data, category);
            }
            else
            {
                this._nonIndexedDataPoint[name] = data;
            }
        }

        /**
         * Append a value to an array data point
         * @param name The name of the array data point to append the data element to
         * @param data The value to append
         * @param isIndexed Whether the data point is indexed
         */
        append(name: string, data: any, isIndexed: boolean = false)
        {
            if (!this._enabled)
            {
                return;
            }

            let existingEvent;

            if (isIndexed)
            {
                existingEvent = this._eventApi.get(name);
            }
            else
            {
                existingEvent = this._nonIndexedDataPoint[name];
            }

            if (!existingEvent)
            {
                existingEvent = [];
            }
            else if (!(existingEvent instanceof Array))
            {
                existingEvent = [existingEvent];
            }

            existingEvent.push(data);

            if (isIndexed)
            {
                this._eventApi.set(name, existingEvent);
            }
            else
            {
                this._nonIndexedDataPoint[name] = existingEvent;
            }
        }

        /**
         * Append a client event to the 'ClientEvents' array data point
         * @param event The event ID or event name of the client event
         * @param eventValue The value of the client event
         */
        appendClientEvent(event: string | number, eventValue: string, dataViewID?: string): void
        {
            if (!this._enabled)
            {
                return;
            }

            if (this._isNumeric(event))
            {
                // Convert it to a proper Numeric value because of the dual type support in the API.
                event = this._getNumericValue(event);
            }

            const eventId = (typeof event === "number") ? event : _w.Telemetry.EClientEvent[event];

            this.append("ClientEvents", {
                ID: eventId,
                EventTime: this._getCurrentTime(),
                Value: eventValue,
                DataViewID: dataViewID
            });
        }

        /**
         * Add a pre-send handler that will be called before events are posted to the event provider. Handlers
         * can synchronously add additional events using the standard TelemetryHelper API
         * @param handler The handler to attach
         * @param context The context to pass to the event handler when it is invoked
         */
        addPreSendHandler(handler: (isViewTransition: boolean, context?: any) => any, context?: any)
        {
            if (!this._enabled)
            {
                return;
            }

            /** Let the callback know whether we are sending events due to a page transition or some other
             * reason (like the queue being full or the send time limit being reached). In particular we
             * only want to add things like PLT events during the pre-send callback if it is a page
             * transition event
             */
            const self = this;

            self._eventApi.addPreSendHandler(
                (onAddContext, onInvokeContext?: IOnInvokeContext) =>
                {
                    handler(onInvokeContext && onInvokeContext.isViewTransition, onAddContext);
                },
                context);
        }

        /**
         * Post events via the event API
         * @param isViewTransition Whether we are calling post due to a view transition (content is changing)
         * or some other reason such as a timeour or the max event limit being exceeded
         * @param eventName The value of the client event
         */
        post(isViewTransition: boolean, eventName?: string)
        {
            if (!this._enabled)
            {
                return;
            }

            eventName = eventName || this._clientEventsConfig.defaultEventName;

            const numEvents = Object.keys(this._nonIndexedDataPoint).length;

            if ((numEvents > 0 && isViewTransition) || (numEvents >= this._clientEventsConfig.minEvents))
            {
                this._eventApi.set(Constants.NonIndexedDataPointKey, JSON.stringify(this._nonIndexedDataPoint));
                this._nonIndexedDataPoint = {};
            }

            if (this._eventApi.hasEvents())
            {
                this._eventApi.post(eventName, { isViewTransition: isViewTransition } as IOnInvokeContext);
            }
        }

        /**
         * PostPageView events via the event API
         * @param pageID PageID is logged for capturing pageview
         * @param eventName Name of the pageView event if there is any
         */
        postPageView(pageID: number, eventName?: string)
        {
            if (!this._enabled)
            {
                return;
            }

            eventName = eventName || this._clientEventsConfig.defaultEventName;

            // logging the pageview
            this.set("ViewID", pageID, true);
            this._eventApi.post(eventName, false);
        }

        /**
         * Set up event bindings on a given element and its descendents based on the presence of data-report
         * attributes
         * @param element The root element on which to search for data-report-* attributes
         */
        applyClientEventBindings(element: HTMLElement)
        {
            if (!this._enabled)
            {
                return;
            }

            const self = this;

            let boundElements: NodeListOf<HTMLElement> = element.querySelectorAll(`[${Constants.ReportEventIdAttr}]`);
            let dataViewID = element.getAttribute(Constants.DataViewId) ? element.getAttribute(Constants.DataViewId) : "";

            for (let i = 0; i < boundElements.length; i++)
            {
                let el: HTMLElement = boundElements[i];

                // ensure we attach the handler only once
                if (el.getAttribute(Constants.ReportEventHandlerAttachedAttr))
                {
                    return;
                }

                const eventId = el.getAttribute(Constants.ReportEventIdAttr);
                let eventValue = el.getAttribute(Constants.ReportEventValueAttr);
                let eventTrigger = el.getAttribute(Constants.ReportEventTriggerAttr);

                if (!eventValue)
                {
                    eventValue = self._inferClientEventValue(el);
                }
                else if (eventValue.indexOf(Constants.ReportEventValueAttrBinding) === 0)
                {
                    /** special handling for attr: syntax to allow passing dynamic values (without
                     *incurring the costs/risks of full eval support)
                     */

                    let attrName = eventValue.slice(Constants.ReportEventValueAttrBinding.length).trim();
                    eventValue = el.getAttribute(attrName);
                }

                const handler =
                    (jsEventName) =>
                    {
                        if (!eventValue)
                        {
                            eventValue = jsEventName;
                        }

                        self.appendClientEvent(eventId, eventValue, dataViewID);
                    };

                /** listen to mousedown instead of click, as other click handlers on the element might synchronously
                 * trigger a view transition. TODO: is a more robust solution needed here? Technically mousedown != click.
                 */
                let eventHandlers: string[] = [Constants.Click, Constants.Dblclick, Constants.Keypress, Constants.Cut, Constants.Copy, Constants.Paste, Constants.Change, Constants.Focus, Constants.Scroll, Constants.Submit, Constants.Reset];
                let currentEvents: string[] = (eventTrigger && eventTrigger.split(",")) || [Constants.Click];

                if (currentEvents.length > 0)
                {
                    for (let j = 0; j < eventHandlers.length; j++)
                    {
                        if (currentEvents.indexOf(eventHandlers[j]) !== -1)
                        {
                            Utils.AddListener(el, eventHandlers[j], () =>
                            {
                                handler(eventHandlers[j]);
                            });
                            el.setAttribute(Constants.ReportEventHandlerAttachedAttr, "1");
                        }
                    }
                }
            }
        }

        _getCurrentTime(): number
        {
            // Support IE8...sigh
            if (!Date.now)
            {
                return new Date().getTime();
            }

            return Date.now();
        }

        hasPageEvents(): boolean
        {
            return Object.keys(this._nonIndexedDataPoint).length > 0;
        }

        _addUnloadHandlers()
        {
            const self = this;
            const sendEvts = () => { self.post(true); };

            Utils.AddListener(_w.document, "visibilitychange", () =>
            {
                if (_w.document.visibilityState === "hidden")
                {
                    sendEvts();
                }
            });

            // Doesn't fire the visibilitychange event when navigating away from a document, so including pagehide which does fire for that case in all current browsers.
            Utils.AddListener(_w, "pagehide", sendEvts);
        }

        _inferClientEventValue(el: HTMLElement): string
        {
            let value: string;

            if (el)
            {
                switch (el.tagName.toLowerCase())
                {
                    case Constants.Input:
                        if (el instanceof HTMLInputElement)
                        {
                            if (el.type === Constants.Radio || el.type === Constants.Checkbox)
                            {
                                value = el.checked ? "checked" : "unchecked";
                            }
                        }
                        else if (el instanceof HTMLButtonElement)
                        {
                            if (el.type === Constants.Button)
                            {
                                value = "clicked";
                            }
                            else
                            {
                                value = el.getAttribute(Constants.ReportEventIdAttr);
                            }
                        }
                        break;
                    case Constants.A:
                        if (el instanceof HTMLAnchorElement)
                        {
                            value = "clicked";
                        }
                        break;
                    default:
                        value = el.getAttribute(Constants.ReportEventIdAttr);
                }
            }
            return value;
        }

        // the isViewTransition flag will be consumed in a subsequent iteration
        // eslint-disable-next-line no-unused-vars
        _setPerRequestDataPoints(isViewTransition: boolean)
        {
            this.set("ServerPageID", this._serverPageID, true, "data");
            this.set("PageName", this._clientEventsConfig.pageName, true, "data");
            this.set("ServiceID", this._serviceID, true, "data");
            this.set("CorrelationId", this._getCorrelationID(), true, "data");
            // app fields
            this.set("id", this._clientEventsConfig.appId, true, "app");
            this.set("ver", this._clientEventsConfig.serverDetails.version, true, "app");
            this.set("name", this._clientEventsConfig.defaultEventName, true, "app");
            // (Placeholder for future)
            //this.set("asId", "", true, "app");
            this.set("sesId", this._getCorrelationID(), true, "app");
            this.set("userId", `p: ${this._clientEventsConfig.actorID}`, true, "app");
            // (Placeholder for future use)
            //this.set("expId", "", true, "app");
            this.set("env", this._clientEventsConfig.environment, true, "app");

            //cloud fields
            this.set("role", this._getCloudrole(), true, "cloud");
            this.set("roleInstance", this._clientEventsConfig.serverDetails.roleInstance, true, "cloud");
            this.set("roleVer", this._clientEventsConfig.serverDetails.version, true, "cloud");

            //mscv field (Investigate in future)
            //this.set("cV", "", true, "mscv");
        }

        _getCloudrole(): string
        {
            return this._clientEventsConfig.serverDetails.datacenter || "-";
        }

        _getCorrelationID(): string
        {
            let correlationID = this._clientEventsConfig.correlationID;

            if (!correlationID)
            {
                // as a last resort, generate a new correlation ID so we can at least correlate across different client data points that are fired within the same host page
                correlationID = Utils.GenerateGUID();
                this._clientEventsConfig.correlationID = correlationID;
            }

            return correlationID;
        }

        _isNumeric(num): boolean
        {
            return !isNaN(num);
        }

        _getNumericValue(num): number
        {
            return Number(num);
        }
    }
}
declare var exports: any;
exports.TelemetryHelper = Telemetry.TelemetryHelper;