
var ko = require("knockout");


function StringCustomizationObservables(customizationLoader)
{
    var _this = this;

    
    _this.isCustomStringsLoadComplete = ko.pureComputed(
        function ()
        {
            if (customizationLoader())
            {
                return customizationLoader().strings.isLoadComplete();
            }

            return !__CUSTOMIZATION_LOADER_ENABLED__;
        });

    _this.customStrings = ko.pureComputed(
        function ()
        {
            if (customizationLoader())
            {
                return customizationLoader().strings();
            }

            return null;
        });

    _this.isCustomStringsLoadFailure = ko.pureComputed(
        function ()
        {
            if (customizationLoader())
            {
                return customizationLoader().strings.isLoadFailure();
            }

            return false;
        });
}

module.exports = StringCustomizationObservables;