namespace Telemetry
{
    const _guidTemplate: string = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx";

    /**
     * Extend EventTarget to include IE8-specific event listener functions
     */
    export interface IECompatibleEventTarget extends EventTarget
    {
        attachEvent?(type: string, listener: EventListener): boolean;
        detachEvent?(type: string, listener: EventListener): void;
    }

    /**
     * Add an event listener to an element (IE8 compatible)
     * @param el - The element to attach listener to
     * @param eventName - The name of the event to listen for
     * @param handler - The handler to invoke when the event fires
     */
    export function AddListener(el: IECompatibleEventTarget, eventName: string, handler: EventListener): void
    {
        if (el && typeof el.addEventListener === "function")
        {
            el.addEventListener(eventName, handler);
        }
        else if (el && el.attachEvent)
        {
            el.attachEvent("on" + eventName, handler);
        }
        else
        {
            throw "Browser supports neither addEventListener nor attachEvent";
        }
    }

    /**
     * Generate a (pseudo-)random GUID
     */
    export function GenerateGUID(): string
    {
        return _guidTemplate.replace(
            /[xy]/g,
            function (ch)
            {
                let num = _GetRandomInt(0, 16);
                if (ch === "y")
                {
                    num = (num & (0x3 | 0x8));
                }

                return num.toString(16);
            });
    }

    function _GetRandomInt(min: number, max: number): number
    {
        return Math.floor(Math.random() * Math.floor(max - min)) + min;
    }

    // Polyfill Object.keys
    // From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/keys
    if (!Object.keys)
    {
        Object.keys =
            (function ()
            {
                "use strict";
                var hasOwnProperty = Object.prototype.hasOwnProperty,
                    // propertyIsEnumerable is required in this case to detect the bug where Object.prototype functions
                    // are enumerable on derived objects
                    // eslint-disable-next-line no-prototype-builtins
                    hasDontEnumBug = !({ toString: null }).propertyIsEnumerable("toString"),
                    dontEnums = [
                        "toString",
                        "toLocaleString",
                        "valueOf",
                        "hasOwnProperty",
                        "isPrototypeOf",
                        "propertyIsEnumerable",
                        "constructor"
                    ],
                    dontEnumsLength = dontEnums.length;

                let keysPolyfill =
                    function (obj)
                    {
                        if (typeof obj !== "function" && (typeof obj !== "object" || obj === null))
                        {
                            throw new TypeError("Object.keys called on non-object");
                        }

                        var result = [], prop, i;

                        for (prop in obj)
                        {
                            if (hasOwnProperty.call(obj, prop))
                            {
                                result.push(prop);
                            }
                        }

                        if (hasDontEnumBug)
                        {
                            for (i = 0; i < dontEnumsLength; i++)
                            {
                                if (hasOwnProperty.call(obj, dontEnums[i]))
                                {
                                    result.push(dontEnums[i]);
                                }
                            }
                        }

                        return result;
                    };

                return keysPolyfill;
            })();
    }

    // Polyfill for supporting indexOf()
    //From https://gist.github.com/szepeviktor/8283170
    if (!Array.prototype.indexOf)
    {
        Array.prototype.indexOf = function (searchElement, fromIndex)
        {
            if (this === undefined || this === null)
            {
                throw new TypeError("'this' is null or not defined");
            }

            var length = this.length >>> 0; // Hack to convert object.length to a UInt32

            fromIndex = +fromIndex || 0;

            if (Math.abs(fromIndex) === Infinity)
            {
                fromIndex = 0;
            }

            if (fromIndex < 0)
            {
                fromIndex += length;
                if (fromIndex < 0)
                {
                    fromIndex = 0;
                }
            }

            for (; fromIndex < length; fromIndex++)
            {
                if (this[fromIndex] === searchElement)
                {
                    return fromIndex;
                }
            }

            return -1;
        };
    }
}

declare var exports: any;
exports.AddListener = Telemetry.AddListener;
exports.GenerateGUID = Telemetry.GenerateGUID;