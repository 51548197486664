var ko = require("knockout");
var Browser = require("./BrowserControl");

var BrowserHelper = Browser.Helper;

var w = window;

function LogoControl(params)
{
    var _this = this;

    var _serverData = w.ServerData;

    
    var _bannerLogoUrl = params.bannerLogoUrl;
    var _isChinaDc = params.isChinaDc;
    

    
    var _isCiamUserFlowUx = _serverData.fIsCiamUserFlowUx;
    var _companyDisplayName = _serverData.sCompanyDisplayName;
    var _useNonMicrosoftDefaultBrandingForCiam = _serverData.fUseNonMicrosoftDefaultBrandingForCiam;
    

    
    _this.bannerLogoUrl = _bannerLogoUrl;
    _this.isChinaDc = _isChinaDc;
    _this.isCiamUserFlowUx = _isCiamUserFlowUx;
    _this.bannerLogoText = null;
    

    (function _initialize()
    {
        if (_useNonMicrosoftDefaultBrandingForCiam)
        {
            var unsafe_companyName = BrowserHelper.htmlUnescape(_companyDisplayName);

            _this.bannerLogoText = unsafe_companyName.toUpperCase();
        }
    })();
}

ko.components.register("logo-control",
    {
        viewModel: LogoControl,
        template: require("html/Shared/Controls/LogoControlHtml.html"),
        synchronous: !w.ServerData.iMaxStackForKnockoutAsyncComponents || Browser.Helper.isStackSizeGreaterThan(w.ServerData.iMaxStackForKnockoutAsyncComponents)
    });

module.exports = LogoControl;