declare var module: any;

module.exports = {

    ReportEventIdAttr: "data-report-event",
    ReportEventValueAttr: "data-report-value",
    ReportEventTriggerAttr: "data-report-trigger",
    ReportEventValueAttrBinding: "attr:",
    ReportEventHandlerAttachedAttr: "data-report-attached",
    DataViewId: "data-viewid",
    NonIndexedDataPointKey: "Data",
    Click: "click",
    Dblclick: "dblclick",
    Keypress: "keypress",
    Cut: "cut",
    Copy: "copy",
    Paste: "paste",
    Change: "change",
    Focus: "focus",
    Scroll: "scroll",
    Submit: "submit",
    Reset: "reset",
    Input: "input",
    A: "a",
    Radio: "radio",
    Checkbox: "checkbox",
    Button: "button",
    ExceptionData: "ExceptionData",
    PageActionData: "PageActionData",
    ExceptionsTablesuffix: "Exceptions",
    PageActtionsTablesuffix: "UserActions"
};