/* eslint-disable */

/* *********************
This file shares some functionality with the npm package in src/SISU/telemetry. When you make a change here, consider if
it also needs to apply to the "modern" (React) Axis stack -- if so, you should update both places.
********************* */

declare var __webpack_public_path__: string, __webpack_nonce__: string;
//This is needed to format the correct url to lazyload oneDs bundle
const serverData = (<any>window).ServerData || {};
const scriptWithNonce = (<any>window).document.querySelector("script[nonce]");
const nonce = scriptWithNonce ? (scriptWithNonce["nonce"] || scriptWithNonce.getAttribute("nonce")) : undefined;
__webpack_nonce__ = nonce;
__webpack_public_path__ = (serverData.urlCdn || serverData.a) + "content/js/";
/* eslint-enable */

namespace Telemetry
{
    declare var require: any;

    const Constants = require("./Constants");
    const Utils = require("./Utils");
    const _w: any = window;
    const c_jsScriptElType = "text/javascript";

    export type PreSendHandler = (onAddContext: Object, onInvokeContext: Object) => void;
    export type PreSendHandlerWrapper = (onInvokeContext: Object) => void;
    export type AppInsightsEvent = { name: string, data: any };

    /*
     * Args passed to EventApi during initialization
     */
    export interface IEventApiArgs
    {
        /*
         * Whether to auto-post events at given intervals.
         */
        autoPost: boolean,

        /*
         * How long to wait between events before auto-posting. The non-obvious parameter name
         * is for compatibility with legacy code.
         */
        flush: number,

        /*
         * The CDN URL hosting the appInsights script.
         */
        providerUrl: string,

        /**
         * The config object to pass to appInsights.initialize. For valid properties refer to the
         * 1DS documentation at https://1dsdocs.azurewebsites.net/api/webSDK/sku/analytics-web/2.3/f/classes/applicationinsights.html#initialize
         */
        appInsightsConfig: any,

        /**
         * The appInsights object passed by the partner page, if any.
         */
        appInsightObject: any,

        /**
         * The default event name to use when posting an event collection to the event provider
         */
        defaultEventName: string,

        /*
         * The configuration for auto capture events in 1DS as a configuration
         */
        autoCaptureJsErrors : boolean,
        autoCaptureClicks : boolean,
        autoCapturePageView : boolean,
        autoCaptureEvents : boolean,

        /*
        * EU Collector URL to use for 1DS initialization for EU AAD customers
        */
        endpointUrl: string

    }

    /**
     * Interface for event providers, which handle posting client telemetry data to a remote endpoint
     */
    export interface IEventProvider
    {
        initialize(args: IEventApiArgs): boolean;
        post(data): void;
    }

    /**
     * Event API used to track and log client telemetry events
     */
    export class EventApi<T extends IEventProvider>
    {
        private _eventApiConfig: IEventApiArgs;
        private _eventProvider: T;
        private _eventDict: Record<string, any>;
        private _autoPostHandle: number;

        private _preSendHandlers: PreSendHandlerWrapper[] = [];

        /**
         * Construct an instance of the EventApi<T> class.
         * @param eventProvider The event provider to use to log events to the remote endpoint
         */
        constructor(eventProvider: T)
        {
            this._eventProvider = eventProvider;
        }

        /**
         * Initialize the Event API
         * @param args The init arguments
         */
        initialize(args: IEventApiArgs): boolean
        {
            this._eventApiConfig = args;
            this._eventDict = {};
            return this._eventProvider.initialize(args);
        }

        /**
         * Add a new named event to the queue
         * @param name The name of the event to add
         * @param data The data of the event to add
         */
        set(name: string, value: any, category = "data"): void
        {
            const _this = this;

            if (_this._eventDict[category] === undefined)
            {
                _this._eventDict[category] = {};
            }

            if (value !== null && value !== undefined)
            {
                _this._eventDict[category][name] = value;
            }
        }

        /**
         * Retrieve an event from the event collection
         * @param name The name of the event
         * @param category Type of the common schema fields
         */
        get(name: string, category = "data"): any
        {
            return this._eventDict[category][name];
        }

        /**
         * Checks to see if there are any outstanding events waiting to be sent
         */
        hasEvents(): boolean
        {
            return this._numEvents() > 0;
        }

        /**
         * This is used to determine if a named event is already present but not yet sent,
         * this added to support working around the server side event limitations where events
         * of a certain type can only be included once for a single request (such as telemetry reporting).
         * @param name The name of the event to check for
         * @param category Type of the common schema fields
         */
        hasEvent(name: string, category = "data"): boolean
        {
            return this._eventDict[category][name] !== undefined;
        }

        /**
         * Clear the events that are currently waiting to be sent
         */
        clear()
        {
            this._eventDict = {};
        }

        /**
         * Post the events to the event provider.
         * @param rootEventName If specified, the pending event dictionary will be interpreted as the 'data'
         * element for an event with the given name. If not specified, the event dictionary itself will be
         * interpreted as the event to send.
         * @param onInvokeContext Context to pass to any preSendHandlers, with the current context (for
         * example a 'closing' flag if the window is closing)
         */
        post(rootEventName?: string, onInvokeContext?: any)
        {
            // First, process pre-send handlers. These may add additional events to the queue
            for (let handlerWrapper of this._preSendHandlers)
            {
                handlerWrapper(onInvokeContext);
            }

            if (this.hasEvents())
            {
                let objEntries: Array<any> = [];

                if (rootEventName)
                {
                    // Filtering the non-schema fields based on the (k,V) Value type and storing them in a separate dictionary.
                    let _dataDict = this._eventDict.data;
                    delete this._eventDict.data;

                    objEntries = [_dataDict, this._eventDict.cloud, this._eventDict.app];

                    if (this.validKeysPresent(objEntries))
                    {
                        this._eventProvider.post({ name: rootEventName, data: _dataDict, ext: this._eventDict });
                    }
                }
                else
                {
                    objEntries = [this._eventDict.cloud, this._eventDict.app];

                    if (this.validKeysPresent(objEntries))
                    {
                        this._eventProvider.post(this._eventDict);
                    }
                }

                this.clear();
            }
        }

        /**
         * Helper method to check if all the fields to be posted to OneDS are present in the predefined dictionary
         * @param objEntries data source which has to be verified for presence of fields
         * @param allkeys Predefined array of set of fields for checking if objEntries are present
         */
        validKeysPresent(objEntries?: Array<any>)
        {
            const AllowedKeyChars: RegExp = /^\w+$/;

            for (let i = 0; i < objEntries.length; i++)
            {
                for (let entry in objEntries[i])
                {
                    if (objEntries[i].hasOwnProperty(entry) && !AllowedKeyChars.test(entry))
                    {
                        return false;
                    }
                }
            }
            return true;
        }


        /**
         * Add a pre-send handler which will be invoked before posting events to the event provider
         * @param handler The handler to invoke before posting events
         * @param context Optional context which will be passed to the handler function when it is invoked later
         */
        addPreSendHandler(handler: PreSendHandler, context?: any)
        {
            this._preSendHandlers.push(
                (onInvokeContext) =>
                {
                    handler(context, onInvokeContext);
                });
        }

        _numEvents()
        {
            return Object.keys(this._eventDict).length;
        }
    }

    export class OneDSEventProvider implements IEventProvider
    {
        private _appInsights: any;
        // TODO: Update URL once the appropriate 1DS files have been uploaded to the IDUX CDN
        private _iduxProviderUrl: string = "https://js.monitor.azure.com/scripts/c/ms.analytics-web-2.min.js";
        private _isAppInsightsLoaded: boolean = false;
        private _pendingEventQueue: AppInsightsEvent[] = [];
        private _args: IEventApiArgs;

        /**
         * Initialize the event provider
         * @param args The init arguments
         */
        initialize(args: IEventApiArgs): boolean
        {
            const _this = this;
            this._args = args;

            // Someone else has already loaded appInsights, no need to do it again
            if (_w.Telemetry.appInsights)
            {
                _this._isAppInsightsLoaded = true;
                return true;
            }

            //lazyload oneDs from CDN
            if (this._args.providerUrl)
            {
                if (_this._checkIfOneDsScriptExists(this._args.providerUrl))
                {
                    _this._initializeAppInsights(new _w.oneDS.AppInsightsCore(), new _w.oneDS.PostChannel(), new _w.oneDS.PropertiesPlugin(), new _w.oneDS.ApplicationInsights());
                }
                else
                {
                    Utils.AddListener(_w, "load",
                        () =>
                        {
                            let scriptEl = _w.document.createElement("script");
                            scriptEl.src = this._args.providerUrl || this._iduxProviderUrl;
                            scriptEl.type = c_jsScriptElType;
                            if (nonce)
                            {
                                scriptEl.setAttribute("nonce", nonce);
                            }

                            Utils.AddListener(scriptEl, "load",
                                () =>
                                {
                                    _this._initializeAppInsights(new _w.oneDS.AppInsightsCore(), new _w.oneDS.PostChannel(), new _w.oneDS.PropertiesPlugin(), new _w.oneDS.ApplicationInsights());
                                });
                            _w.document.body.appendChild(scriptEl);
                        });
                }
            }
            //lazyload OneDs from node modules
            else
            {
                require.ensure([], function ()
                {
                    const oneDSCore = require("oneDSCore");
                    const oneDSPost = require("oneDSPost");
                    const oneDSAnalytics = require("oneDSAnalytics");
                    const oneDSProperty = require("oneDSProperties");
                    _this._initializeAppInsights(new oneDSCore.AppInsightsCore(), new oneDSPost.PostChannel(),new oneDSProperty.PropertiesPlugin(),new oneDSAnalytics.ApplicationInsights());
                }, "oneDs");
            }

            return true;
        }

        /**
         * Post data to the OneDS endpoint
         * @param data The data to post
         */
        post(data: AppInsightsEvent): void
        {
            if (this._isAppInsightsLoaded)
            {
                this._postToAppInsights([data]);
                return;
            }

            this._pendingEventQueue.push(data);
        }

        private _postToAppInsights(queuedData: AppInsightsEvent[]): void
        {
            for (let data of queuedData)
            {
                if (this._isAppInsightsManager(_w.Telemetry.appInsights))
                {
                    // create a new AppInsights instance from the manager
                    if (!this._appInsights)
                    {
                        this._appInsights = _w.Telemetry.appInsights.newInst(this._args.appInsightsConfig.instrumentationKey, [], []);
                    }
                    this._appInsights.track(data);
                }
                else
                {
                    _w.Telemetry.appInsights.track(data);
                }
            }
        }

        // the arguments in the function below are to pass the initialised 1ds plugins for use to capture exceptions and support the current existing implementation of 1DS
        // appInsightsCore 1DS Web SDK Core is the telemetry orchestrator, responsible for initializing all attached plugins
        // postChannel  - sends data to one collector using post
        // propertiesPlugin - Used to enhance telemetry events; adding multiple part A properties.
        // webAnalyticsPlugin - Gathers telemetry in Web pages; includes automatic tracking of clicks, page views, content updates, client errors and page unload events, also exposing APIs for all of these events to be manually send.
        // Documentation for the above can be found in the 1DS general documentation https://eng.ms/docs/products/geneva/collect/instrument/1ds/javascriptsdk/getting-started
        private _initializeAppInsights(appInsightsCore: any, postChannel: any, propertiesPlugin: any, webAnalyticsPlugin: any): void
        {
            if (!_w.Telemetry.appInsights)
            {
                if (this._args.appInsightObject)
                {
                    _w.Telemetry.appInsights = this._args.appInsightObject;
                }
                else
                {
                    _w.Telemetry.appInsights = this._createAppInsightsCore(appInsightsCore, postChannel, propertiesPlugin, webAnalyticsPlugin);
                }
            }

            this._isAppInsightsLoaded = true;

            if (this._pendingEventQueue.length > 0)
            {
                this._postToAppInsights(this._pendingEventQueue);
            }
        }

        private _checkIfOneDsScriptExists(url: string): boolean
        {
            const scripts: HTMLCollectionOf<HTMLScriptElement> = document.getElementsByTagName("script") as any;

            for (let i = 0; i < scripts.length; i++)
            {
                if (scripts[i].src === url)
                {
                    return true;
                }
            }

            return false;
        }

        private _isAppInsightsManager(appInsightsObject: any): boolean
        {
            return typeof appInsightsObject.newInst === "function";
        }

        private _createAppInsightsCore(appInsightsCore, postChannel, propertiesPlugin, webAnalyticsPlugin): any
        {
            let appInsightObject: any = null;

            if (this._args.autoCaptureEvents === true)
            {
                const coreLibConfig =
                {
                    instrumentationKey: this._args.appInsightsConfig.instrumentationKey,
                    ...this._args.endpointUrl && { endpointUrl: this._args.endpointUrl },
                    extensions: [
                        propertiesPlugin
                    ],
                    channelConfiguration:
                    {
                        eventsLimitInMem: 50
                    },
                    propertyConfiguration:
                    {
                        hashIdentifiers: true
                    },
                    extensionConfig: [],
                    webAnalyticsConfiguration:
                    {
                        autoCapture:
                        {
                            scroll: false,
                            pageView: false,
                            onLoad: false,
                            onUnload: false,
                            click: this._args.autoCaptureClicks,
                            resize: false,
                            jsError: this._args.autoCaptureJsErrors
                        }
                    }
                };

                //Initialize SDK
                webAnalyticsPlugin.initialize(coreLibConfig, []);

                var telemetryInitializer = (collectedData) =>
                {
                    let propertyContext = propertiesPlugin.getPropertiesContext();
                    let tableName = "";

                    if (_w.ServerData && (collectedData.baseType === Constants.ExceptionData || collectedData.baseType === Constants.PageActionData))
                    {
                        propertyContext.cloud.role = _w.ServerData.serverDetails.dc;
                        propertyContext.cloud.roleInstance = _w.ServerData.serverDetails.ri;
                        propertyContext.cloud.roleVer = _w.ServerData.serverDetails.ver;
                        propertyContext.app.ver = _w.ServerData.serverDetails.ver;
                        propertyContext.app.id = _w.ServerData.clientEvents.appId;
                        propertyContext.app.env = _w.ServerData.environment;
                        propertyContext.app.sesId = _w.ServerData.correlationId;
                    }
                    if (collectedData.baseType === Constants.ExceptionData)
                    {
                        tableName = this._args.defaultEventName + Constants.ExceptionsTablesuffix;
                        collectedData.name = tableName;
                    }
                    else if (collectedData.baseType === Constants.PageActionData)
                    {
                        tableName = this._args.defaultEventName + Constants.PageActtionsTablesuffix;
                        collectedData.name = tableName;
                    }
                };
                webAnalyticsPlugin.addTelemetryInitializer(telemetryInitializer);
                appInsightObject = webAnalyticsPlugin;
            }
            else
            {
                const coreConfig =
                {
                    instrumentationKey: this._args.appInsightsConfig.instrumentationKey,
                    ...this._args.endpointUrl && { endpointUrl: this._args.endpointUrl },
                    extensions:
                        [
                            postChannel,
                            propertiesPlugin
                        ],
                    extensionConfig: []
                };
                const postChannelConfig =
                {
                    eventsLimitInMem: 50
                };
                const propertiesPluginConfig =
                {
                    hashIdentifiers: true
                };

                coreConfig.extensionConfig[postChannel.identifier] = postChannelConfig;
                if (this._args.appInsightsConfig)
                {
                    coreConfig.extensionConfig = this._args.appInsightsConfig;
                }
                coreConfig.extensionConfig[propertiesPlugin.identifier] = propertiesPluginConfig;

                //Initialize SDK
                appInsightsCore.initialize(coreConfig, []);

                appInsightObject = appInsightsCore;
            }

            return appInsightObject;
        }
    }

    export class EmptyEventProvider implements IEventProvider
    {
        constructor()
        {

        }

        initialize(): boolean
        {
            return true;
        }

        post(): void
        {
            return;
        }
    }
}

declare var exports: any;
exports.EventApi = Telemetry.EventApi;
exports.OneDSEventProvider = Telemetry.OneDSEventProvider;
exports.EmptyEventProvider = Telemetry.EmptyEventProvider;